jQuery(function ($) {
    $(document).ready(function () {
        const usStates = [
            { abbreviation: "AL", name: "Alabama" },
            { abbreviation: "AK", name: "Alaska" },
            { abbreviation: "AZ", name: "Arizona" },
            { abbreviation: "AR", name: "Arkansas" },
            { abbreviation: "CA", name: "California" },
            { abbreviation: "CO", name: "Colorado" },
            { abbreviation: "CT", name: "Connecticut" },
            { abbreviation: "DE", name: "Delaware" },
            { abbreviation: "FL", name: "Florida" },
            { abbreviation: "GA", name: "Georgia" },
            { abbreviation: "HI", name: "Hawaii" },
            { abbreviation: "ID", name: "Idaho" },
            { abbreviation: "IL", name: "Illinois" },
            { abbreviation: "IN", name: "Indiana" },
            { abbreviation: "IA", name: "Iowa" },
            { abbreviation: "KS", name: "Kansas" },
            { abbreviation: "KY", name: "Kentucky" },
            { abbreviation: "LA", name: "Louisiana" },
            { abbreviation: "ME", name: "Maine" },
            { abbreviation: "MD", name: "Maryland" },
            { abbreviation: "MA", name: "Massachusetts" },
            { abbreviation: "MI", name: "Michigan" },
            { abbreviation: "MN", name: "Minnesota" },
            { abbreviation: "MS", name: "Mississippi" },
            { abbreviation: "MO", name: "Missouri" },
            { abbreviation: "MT", name: "Montana" },
            { abbreviation: "NE", name: "Nebraska" },
            { abbreviation: "NV", name: "Nevada" },
            { abbreviation: "NH", name: "New Hampshire" },
            { abbreviation: "NJ", name: "New Jersey" },
            { abbreviation: "NM", name: "New Mexico" },
            { abbreviation: "NY", name: "New York" },
            { abbreviation: "NC", name: "North Carolina" },
            { abbreviation: "ND", name: "North Dakota" },
            { abbreviation: "OH", name: "Ohio" },
            { abbreviation: "OK", name: "Oklahoma" },
            { abbreviation: "OR", name: "Oregon" },
            { abbreviation: "PA", name: "Pennsylvania" },
            { abbreviation: "RI", name: "Rhode Island" },
            { abbreviation: "SC", name: "South Carolina" },
            { abbreviation: "SD", name: "South Dakota" },
            { abbreviation: "TN", name: "Tennessee" },
            { abbreviation: "TX", name: "Texas" },
            { abbreviation: "UT", name: "Utah" },
            { abbreviation: "VT", name: "Vermont" },
            { abbreviation: "VA", name: "Virginia" },
            { abbreviation: "WA", name: "Washington" },
            { abbreviation: "WV", name: "West Virginia" },
            { abbreviation: "WI", name: "Wisconsin" },
            { abbreviation: "WY", name: "Wyoming" },
        ];

        function getStateNameByAbbreviation(abbreviation) {
            const state = usStates.find(
                (state) => state.abbreviation === abbreviation.toUpperCase()
            );

            return state ? state.name : "State not found";
        }

        $(".national-map--issue-selector-desktop-all-item-carrot").on(
            "click",
            function () {
                $(".national-map--issue-selector-desktop-all").removeClass(
                    "active"
                );
                $(".national-map--issue-selector-desktop-active-item")
                    .show()
                    .addClass("active");
            }
        );

        $(".national-map--state-selector-mobile-all-item-carrot").on(
            "click",
            function () {
                $(".national-map--state-selector-mobile-all").removeClass(
                    "active"
                );
                $(".national-map--state-selector-mobile-active-item")
                    .show()
                    .addClass("active");
            }
        );

        $(".national-map--issue-selector-mobile-all-item-carrot").on(
            "click",
            function () {
                $(".national-map--issue-selector-mobile-all").removeClass(
                    "active"
                );
                $(".national-map--issue-selector-mobile-active-item")
                    .show()
                    .addClass("active");
            }
        );

        // DESKTOP issue selector
        $(".national-map--issue-selector--item").on("click", function () {
            let selectedIssue = $(this).attr("data-id");
            $(".state-highlight").hide();
            $(".statistic-row, .content-row, .sub-category").show();
            $(
                ".national-map--issue-selector--item, .national-map--issue-wrapper--issue-sub-category, .national-map--issue-wrapper--map"
            ).removeClass("active");
            $(this).addClass("active");
            $("#issue-" + selectedIssue).addClass("active");
            $("#map-" + selectedIssue).addClass("active");
        });

        $(".national-map--issue-selector-desktop-active-item").on(
            "click",
            function () {
                $(this).hide().removeClass("active");
                $(".national-map--issue-selector-desktop-all").addClass(
                    "active"
                );
            }
        );

        // desktop issue selector - set active issue and change content
        $(".national-map--issue-selector-desktop-all-item").on(
            "click",
            function () {
                console.log("here");
                const selectedIssue = $(this).attr("data-issue");
                const selectedIssueId = $(this).attr("data-id");
                $(".national-map--content-wrap").attr(
                    "data-issue",
                    selectedIssue
                );
                $(this).parent().removeClass("active");
                $(".state-highlight").hide();
                $(
                    ".national-map--issue-selector-desktop-active-item-text"
                ).text(selectedIssue);
                $(".national-map--issue-selector-desktop-active-item").show();
                $(".national-map--state-selector-desktop-active-item").addClass(
                    "active"
                );
                $(
                    ".national-map--issue-wrapper--issue-sub-category, .national-map--issue-wrapper--map, .state-highlight-text"
                ).removeClass("active");
                $("#issue-" + selectedIssueId).addClass("active");
                $("#map-" + selectedIssueId).addClass("active");
                $(".state-highlight-text-" + selectedIssueId).addClass(
                    "active"
                );
                $(".statistic-row, .content-row, .sub-category").show();
                circleProgress();
            }
        );

        // MOBILE state selector - set dropdown
        $(".national-map--state-selector-mobile-active-item").on(
            "click",
            function () {
                $(this).removeClass("active");
                $(".national-map--issue-selector-mobile-all").removeClass(
                    "active"
                );
                $(".national-map--state-selector-mobile-all").addClass(
                    "active"
                );
            }
        );

        // MOBILE state selector - set active issue and change content
        $(".national-map--state-selector-mobile-all-item").on(
            "click",
            function () {
                const selectedState = $(this).attr("data-state");
                const selectedStateAbbr = $(this).attr("data-state-abbr");
                const abbrUpper = selectedStateAbbr.toUpperCase();
                const stateObj = $(
                    ".national-map--issue-wrapper--map.active #" + abbrUpper
                );
                if (selectedState !== "na") {
                    $(
                        ".national-map--state-selector-mobile-active-item-text"
                    ).html(
                        '<span class="state-abbr">' +
                            abbrUpper +
                            "</span> | " +
                            selectedState
                    );
                    stateHighlight(stateObj, "mobile");
                    $(".state-highlight.mobile").show();
                    circleProgress();
                }

                $(".national-map--issue-selector-mobile-all").add("active");
                $(this).parent().removeClass("active");
                $(".national-map--state-selector-mobile-active-item").show();
            }
        );

        // MOBILE issue selector - set dropdown
        $(".national-map--issue-selector-mobile-active-item").on(
            "click",
            function () {
                $(this).removeClass("active");
                $(".national-map--state-selector-mobile-all").removeClass(
                    "active"
                );
                $(".national-map--issue-selector-mobile-all").addClass(
                    "active"
                );
            }
        );

        // MOBILE issue selector - set active issue and change content
        $(".national-map--issue-selector-mobile-all-item").on(
            "click",
            function () {
                const selectedIssue = $(this).attr("data-issue");
                const selectedIssueId = $(this).attr("data-id");
                // const mapType = $(this).attr("data-id");
                $(this).parent().removeClass("active");
                $(".state-highlight.mobile").hide();
                $(".state-highlight-text").removeClass("active");
                $(".state-highlight-text-" + selectedIssueId).addClass(
                    "active"
                );
                $(".national-map--state-selector-mobile-active-item-text").text(
                    "Choose a State"
                );
                $(".national-map--state-selector-mobile-all").add("active");
                $(".national-map--issue-selector-mobile-active-item-text").text(
                    selectedIssue
                );
                $(".national-map--issue-selector-mobile-active-item").show();
                $(".national-map--state-selector-mobile-active-item").addClass(
                    "active"
                );
                $(
                    ".national-map--issue-wrapper--issue-sub-category, .national-map--issue-wrapper--map"
                ).removeClass("active");
                $("#issue-" + selectedIssueId).addClass("active");
                $("#map-" + selectedIssueId).addClass("active");
                circleProgress();
            }
        );

        // handle map svg click
        $(".national-map--issue-wrapper--map .state").on("click", function () {
            stateHighlight($(this), "desktop");
        });

        // close state highlight
        $(".state-close-button").on("click", function (e) {
            e.preventDefault();
            $(".statistic-row, .content-row, .sub-category").show();
            $(".state-highlight").hide();
        });

        // function to set state highlight
        function stateHighlight(obj, device) {
            let deviceClass;
            if (device === "mobile") {
                deviceClass = ".state-highlight.mobile";
            } else {
                deviceClass = ".state-highlight.desktop";
            }
            let mapType = $(".state-highlight-text.active").attr("data-type");
            $(".state-highlight--info-wrapper")
                .removeClass("range")
                .removeClass("value")
                .addClass(mapType);

            console.log(mapType);
            $(
                ".state-highlight--info-wrapper .circular-progress, .state-highlight-info--data"
            ).hide();
            const abbreviation = obj.attr("id");
            const stateName = getStateNameByAbbreviation(abbreviation);
            const issueName = $(".national-map--content-wrap").attr(
                "data-issue"
            );
            const weight =
                Number(obj.attr("data-weight")) !== NaN
                    ? obj.attr("data-weight")
                    : 100;
            if (device === "desktop") {
                $(".statistic-row, .content-row, .sub-category").hide();
            }
            $(deviceClass + " .state-name").text(stateName);
            $(deviceClass + "").show();
            $(deviceClass + " a.state-button").attr(
                "href",
                "/state/" + abbreviation + "?issueval=" + issueName
            );
            if (!isNaN(weight)) {
                $(deviceClass + " .circular-progress").attr(
                    "data-percentage",
                    weight
                );
            }
            $(deviceClass + " .state-highlight-info--data").text(weight);
            if (mapType === "range") {
                $(".state-highlight--info-wrapper .circular-progress").show();
                $(".state-highlight-info--data").hide();
                circleProgress();
            } else {
                $(".state-highlight--info-wrapper .circular-progress").hide();
                $(".state-highlight-info--data").show();
            }
        }

        // progress bar animation
        circleProgress();
        function circleProgress() {
            const circularProgress =
                document.querySelectorAll(".circular-progress");

            Array.from(circularProgress).forEach((progressBar) => {
                if (
                    Number(progressBar.getAttribute("data-percentage")) !== NaN
                ) {
                    const progressValue =
                        progressBar.querySelector(".percentage");
                    const innerCircle =
                        progressBar.querySelector(".inner-circle");
                    let startValue = 0,
                        endValue =
                            Number(
                                progressBar.getAttribute("data-percentage")
                            ) !== NaN
                                ? Number(
                                      progressBar.getAttribute(
                                          "data-percentage"
                                      )
                                  )
                                : 100,
                        speed = 10,
                        progressColor = progressBar.getAttribute(
                            "data-progress-color"
                        );

                    const progress = setInterval(() => {
                        startValue++;
                        progressValue.textContent = `${startValue}%`;
                        progressValue.style.color = `${progressColor}`;

                        innerCircle.style.backgroundColor = `${progressBar.getAttribute(
                            "data-inner-circle-color"
                        )}`;

                        progressBar.style.background = `conic-gradient(${progressColor} ${
                            startValue * 3.6
                        }deg,${progressBar.getAttribute(
                            "data-bg-color"
                        )} 0deg)`;
                        if (startValue === endValue) {
                            clearInterval(progress);
                        }
                    }, speed);
                }
            });
        }

        //function get get percentage out of gradient range
        function pickHex(color1, color2, weight) {
            var w1 = weight;
            var w2 = 1 - w1;
            var rgb = [
                Math.round(color1[0] * w1 + color2[0] * w2),
                Math.round(color1[1] * w1 + color2[1] * w2),
                Math.round(color1[2] * w1 + color2[2] * w2),
            ];
            return rgb;
        }

        function calculatePercentage(number, minimum, maximum) {
            let min = parseFloat(minimum.replace(/%/g, ""));
            let max = parseFloat(maximum.replace(/%/g, ""));
            // Ensure that minimum is less than maximum
            if (min >= max) {
                return "Error: Minimum must be less than maximum.";
            }

            // Calculate the percentage
            let percentage = ((number - min) / (max - min)) * 100;

            return percentage;
        }

        // set fill color on map with proper weight
        // function setFillColor() {
        //     let state = $(".state");
        //     for (let i = 0; i < state.length; i++) {
        //         let weight = state[i].getAttribute("data-weight");
        //         if (weight !== "na") {
        //             let stateColor = pickHex(
        //                 [73, 37, 105],
        //                 [214, 200, 240],
        //                 parseFloat(weight) / 100
        //             );
        //             state[i].style.fill = "rgb(" + stateColor + ")";
        //         }
        //     }
        // }
        function setFillColor() {
            // let state = $(".district");
            let state = document.getElementsByClassName("state");
            for (let i = 0; i < state.length; i++) {
                if (
                    $(state[i])
                        .parent()
                        .parent()
                        .parent()
                        .parent()
                        .attr("data-map-type") === "range"
                ) {
                    let parent = $(state[i]).parent().parent().parent();

                    let min = parent.attr("data-legend-low");
                    let max = parent.attr("data-legend-high");
                    let weight = state[i].getAttribute("data-weight");
                    // get the percentage between two numbers - useful because range is not always 0-100
                    let percentage = calculatePercentage(
                        parseFloat(weight),
                        min,
                        max
                    );

                    if (weight !== "na") {
                        let stateColor = pickHex(
                            [73, 37, 105],
                            [214, 200, 240],
                            parseFloat(percentage) / 100
                        );
                        state[i].style.fill = "rgb(" + stateColor + ")";
                    }
                }
            }
        }

        // const mapType = $(".map-container").attr("data-map-type");
        setFillColor();

        // end ready function
    });
});
